body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 29px;
  height: 16px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch .toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c7c7d1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  height: 15px;
}
.switch .toggle:before {
  position: absolute;
  content: "";
  height: 14.5px;
  width: 14.5px;
  bottom: 0px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  left: 0px;
  border: 0.5px solid #c7c7d1;
}

.switch input:checked + .toggle {
  background-color: #34ad34;
}

.switch input:checked + .toggle:before {
  transform: translateX(14px);
}
.switch-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
